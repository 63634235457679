<template>
    <div>
        <a-row :gutter="16">
            <a-col :xs="24" :md="8">
                <a-card>
                    <p><strong>CODIGO : </strong> {{ showproducto.codigo }}</p>
                    <p><strong>SUCURSAL : </strong> {{ dataproducto.nombreAlmacen }}</p>
                </a-card>
                </a-col>

                <a-col :xs="24" :md="12">
                <a-card>
                    <p><strong>NOMBRE : </strong> {{ showproducto.nombre }}</p>
                    <p><strong>CATEGORIA : </strong> {{ dataproducto.categoria.nombre }}</p>
                </a-card>
                </a-col>

                <a-col :xs="24" :md="4">
                <a-card>
                    <p><strong>MEDIDA : </strong> {{ showproducto.medida }}</p>
                </a-card>
            </a-col>
        </a-row>

        <br>

        <a-row :gutter="5">
            <a-col :xs="24" :md="4">
                <a-form-item label="Tipo Ingreso">
                    <a-select :value="compra.tipoingreso_id" @change="onChageTipoIngreso" style="width: 100%">
                    <a-select-option v-for="(value, index) in lista_tipoingresos" :key="index" :value="value.tipoingreso_id">
                        {{ value.nombre }}
                    </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :xs="24" :md="4" v-if="compra.tipoingreso_id == 2 || compra.tipoingreso_id == 3">
                <a-form-item label="Tipo Compra">
                    <a-select :value="compra.plancuenta_id" @change="onChageTipoCompra" style="width: 100%">
                    <a-select-option v-for="(value, index) in lista_plancuenta" :key="index" :value="value.plancuenta_id">
                        {{ value.nombre_corto }}
                    </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :xs="24" :md="4" v-show="compra.tipoingreso_id == 2">
                <a-form-item label="N.Comprobante">
                    <a-input
                    type="text"
                    v-model="compra.nrodocumento"
                    class="input-sm"
                    />
                </a-form-item>
            </a-col>

            <a-col :xs="24" :md="4" v-show="compra.tipoingreso_id == 2 || compra.tipoingreso_id == 3">
                <a-form-item label="Fecha de Compra">
                    <a-date-picker
                    :value="getDateFormat(compra.fecha_compra)"
                    @change="onChangeFechaCompra"
                    :locale="locale"
                    style="width: 100%"
                    />
                </a-form-item>
            </a-col>

            <a-col :xs="24" :md="8">
                <a-form-item label="Observaciones">
                    <a-textarea
                    v-model="compra.observaciones"
                    class="input-sm"
                    />
                </a-form-item>
            </a-col>
        </a-row>
                
        <div class="row">
            <div class="form-group col-md-12">
                <div class="box-body table-responsive no-padding">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>NRO</th>
                                <th><center>PROVEEDOR</center></th>
                                <th><center>STOCK</center></th>
                                <th>ARTICULO</th>
                                <th><center>CANT</center></th>
                                <th><center>P.COMPRA</center></th>
                                <th><center>SUBTOTAL</center></th>
                                <th><center>DESC</center></th>
                                <th><center></center></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(item, index) in tablaproducto" :key="index">
                                <td>{{ index + 1 }}</td>
                                                
                                <td>{{ item.proveedor.empresa }}</td>

                                <td bgcolor="#eeffd0" style="text-align: center;">{{ item.saldoFisico }}</td>
                                                
                                <td>{{ item.codigo }} {{ item.nombre }} {{ item.medida }}</td>
                                                
                                <td bgcolor="#E0FFD3" style="text-align: center;" @dblclick="newCantidad = (index+'_'+item.producto_id);valueCantidad=item.cantidad">
                                    <label v-show="newCantidad != (index+'_'+item.producto_id)">{{ item.cantidad }}</label>
                                    <input style="width: 50px" v-if="newCantidad == (index+'_'+item.producto_id)" v-model="valueCantidad" v-on:blur="editCantidad(index,item.producto_id); $emit('update')" @keyup.enter="editCantidad(index,item.producto_id); $emit('update')" v-on:keyup="validacionCantidad()">
                                </td>
                                                
                                <td bgcolor="#FEFFCA" style="text-align: center;" @dblclick="newPrecioCompra = (index+'_'+item.producto_id);valuePrecioCompra=item.precioCompra">
                                    <label v-show="newPrecioCompra != (index+'_'+item.producto_id)">{{ item.precioCompra }}</label>
                                    <input  style="width: 60px" v-if="newPrecioCompra == (index+'_'+item.producto_id)" v-model="valuePrecioCompra" v-on:blur="editPrecioCompra(index,item.producto_id); $emit('update')" @keyup.enter="editPrecioCompra(index,item.producto_id); $emit('update')" v-on:keyup="validacionDecimal()">
                                </td>
                                                
                                <td><center>{{ item.subtotal }}</center></td>
                                                
                                <td bgcolor="#fff1f1" style="text-align: center;" @dblclick="itemDescuentoCompra = (index+'_'+item.producto_id);valueDescuentoCompra=item.descuentoCompra">
                                    <label v-show="itemDescuentoCompra != (index+'_'+item.producto_id)">{{ item.descuentoCompra }}</label>
                                    <input  style="width: 60px" v-if="itemDescuentoCompra == (index+'_'+item.producto_id)" v-model="valueDescuentoCompra" v-on:blur="editItemDescuentoCompra(index,item.producto_id); $emit('update')" @keyup.enter="editItemDescuentoCompra(index,item.producto_id); $emit('update')" v-on:keyup="validacionDecimalDescuentoCompra()">
                                </td>

                                <td>
                                    <center>
                                        <a href="#" @click.prevent="deleteItem(index)">
                                            <a-icon type="close-circle" :style="{ fontSize: '20px', color: '#EE0808' }" />
                                        </a>
                                    </center>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <a-row :gutter="16" v-if="habilitarajustes == false">
    <a-col :span="24">
      <div>
        Ingreso de Productos al Almacen o Sucursal.
      </div>
    </a-col>

    <a-col :span="24" :md="16"></a-col>
    <a-col :span="24" :md="8" v-if="habilitarajustes == false">
      <p>
        <strong>SUBTOTAL :</strong>
        <span class="pull-right">
          <font size="4" style="color: green;">{{ monedasimbolo }} {{ subTotal }}</font>
        </span>
      </p>
      <p>
        <strong>DESCUENTO :</strong>
        <span class="pull-right">
          <font size="4" style="color: #FD4949;">{{ monedasimbolo }} {{ TotalDescuentoCompra }}</font>
        </span>
      </p>
      <p>
        <strong>MONTO TOTAL :</strong>
        <span class="pull-right">
          <font size="4" style="color: #323232;">{{ monedasimbolo }} {{ montoTotal }}</font>
        </span>
      </p>
      <hr>
      <div class="callout bg-black-active" style="background-color: black;">
        <center>
          <label style="color: white;">MONTO TOTAL</label>
        </center>
        <center>
          <font size="6" color="#0ed104">
            <strong><p>{{ montoTotal }}</p></strong>
          </font>
        </center>
      </div>
    </a-col>

    <a-col :span="24" :md="16"></a-col>

    <a-col :span="24" :md="8" v-if="deshabilitarInventarioInicial == false && habilitarcredito == false">
      <a-button
        type="primary"
        size="large"
        block
        style="background-color: #5cc00b; border-color: #5cc00b; color: white;"
        @click.once="realizarIngreso()"
        :key="buttonKey"
        :disabled="deshabilitarInventarioInicial == true"
      >
        <font size="4">
          <center><strong>GUARDAR INGRESO</strong></center>
        </font>
      </a-button>
    </a-col>

    <a-col :span="24" :md="8" v-if="habilitarcredito == true">
      <a-button
        type="primary"
        size="large"
        block
        style="background-color: #04bc84; border-color: #04bc84; color: white;"
        @click.once="realizarIngreso()"
        :key="buttonKey"
        :disabled="deshabilitarInventarioInicial == true"
      >
        <font size="4">
          <center><strong>GUARDAR INGRESO A CREDITO</strong></center>
        </font>
      </a-button>
    </a-col>
  </a-row>
        </div> 
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999;">
            <div style="margin-top: 250px;">
                <VueSimpleSpinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..." />
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
        
    </div>
</template>
    
<script>
    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import moment from 'moment'
    import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
    import { mapActions, mapGetters } from 'vuex'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
    import VueSimpleSpinner from 'vue-simple-spinner'

    import { 
		URI_TIPO_INGRESO_GET_LISTAR,
        URI_PLANCUENTA_LISTAR_COMPRA,
        URI_PRODUCTO_INICIAL,
        URI_PRODUCTO_POST_COMPRA,
        URI_COMPRA_INVENTARIO_INICIAL,
        URI_COMPRA_GUARDAR
	} from '@/utils/constants'

    export default {
    
        props: {
            utilidadporcentaje: { type: String, required: true },
            preciocifradecimal: { type: String, required: true },
            preciocifraentera: { type: String, required: true },
            precioreglatres: { type: String, required: true },
            monedasimbolo: { type: String, required: true },
            dataproducto: { required: true },
            almacenid: { 
                type: String, 
                required: true
            },
            proveedorid: { 
                type: String, 
                required: true,
                validator: function (value) {
                    return value > 0
                } 
            }
        },
    
        components: {
            VueSimpleSpinner
        },
    
        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                almacen_id: '',
                compra: {
                    almacen_id: '',
                    tipoingreso_id: 'Seleccionar',
                    plancuenta_id: '',
                    proveedor_id: '',
                    nit_proveedor: '',
                    fecha_compra: '',
                    observaciones: 'SIN OBSERVACIONES',
                    razon_social: '',
                    autorizacion: 0,
                    factura: 0,
                    codigo_control: '',
                    ice: 0.0,
                    valores_exceptos: 0.0,
                    descuentos: 0.0, 
					nrodocumento: '0'
                },
                getproducto_id: 0,
                lista_tipoingresos: [],
                lista_plancuenta: [],
                tablaproducto: [],
                validatedFormTipoIngreso: false,
                habilitarajustes: false,
                validatedFormTipoCompra: false,
                habilitarcredito: false,
                deshabilitarInventarioInicial: false,
                validatedForm: false,
                validatedFormAlmacen: false,
                newCantidad: null,
                valueCantidad: null,
                newPrecioCompra: null,
                valuePrecioCompra: null,
                itemDescuentoCompra: null,
                valueDescuentoCompra: null,
                descuentoCompra: '0.00',
                showproducto: {
                    codigo: '',
                    nombre: '',
                    medida: ''
                },
                spinnerloading: false,
                buttonKey: 10,
                asignacion: {
                    tipoprecioventaID: '',
                    almacenID: '',
                    productoID: '',
                    proveedorID: '',
                    utilidad: '',
                    precioventa: '',
                    producto: {},
                    precioCosto: '',
                    preciofacturado: ''
                },
                buttonKeyNuevaVenta: 20,
                openModalEditarPrecioVentaCompra: false,
                showModalActualizarVencimiento: false,
                buttonKeyFechaVencimiento: 30,
                buttonKeyPrecioVenta: 40
            }
        }, 
    
        created () {
            this.getTipoIngreso()
            this.getTipoCompras()
            this.initCompra(this.dataproducto)
            this.compra.fecha_compra = moment().format('YYYY-MM-DD')
        },
    
        methods: {
            ...mapActions('producto', ['getListaScrollMasivoProducto', 'getListaScrollMasivoProductoStock']),

            numberFormat (number) {
				return utils.formatMoney(number, 2, '.', '')
			},

            getDateFormat (date) {
				const d = moment(date)
				return date && d.isValid() ? d : ''
			},

            resetCompra () {
                this.tablaproducto = []
                this.getproducto_id = 0
                this.showproducto.codigo = ''
                this.showproducto.nombre = ''
                this.showproducto.medida = ''
                this.almacen_id = ''
                this.compra.almacen_id = ''
                this.compra.proveedor_id = ''
            },

            initCompra (item) {
                this.resetCompra()
                
                setTimeout(function () {
                    this.getproducto_id = item.producto_id
                    this.showproducto.codigo = item.codigo
                    this.showproducto.nombre = item.nombre
                    this.showproducto.medida = item.medida
                    this.almacen_id = item.almacenId
                    this.compra.almacen_id = item.almacenId
                    this.compra.proveedor_id = item.proveedorID

                    if (item.stocks) {
                        if (Object.entries(item.stocks).length === 0) {
                            // Inventario Inicial
                            const tipoingreso = '1'
                            this.compra.tipoingreso_id = '1'
                            this.agregarProducto(tipoingreso)
                        } else if (Object.entries(item.stocks).length > 0) {
                            // Compra
                            const tipoingreso = '2'
                            this.compra.tipoingreso_id = '2'
                            this.agregarProducto(tipoingreso)
                        } 
                    } else {
                        console.log('compra con stock registrado en sucursal')
                        // Compra
                        const tipoingreso = '2'
                        this.compra.tipoingreso_id = '2'
                        this.agregarProducto(tipoingreso)
                    }
                }.bind(this), 500)
            },

            getTipoIngreso () {
                axios.get(URI_TIPO_INGRESO_GET_LISTAR)
                .then(response => {
                    this.lista_tipoingresos = response.data
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getTipoCompras () {
                axios.get(URI_PLANCUENTA_LISTAR_COMPRA)
                .then(response => {
                    this.lista_plancuenta = response.data
                    this.compra.plancuenta_id = Object.values(this.lista_plancuenta).filter(x => typeof x !== 'undefined').shift().plancuenta_id
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onChageTipoIngreso (value) {
                this.tablaproducto = []

                setTimeout(function () { 
                    this.compra.tipoingreso_id = value
                
                    if (this.compra.tipoingreso_id === '4') {
                        this.habilitarajustes = true
                    } else {
                        this.habilitarajustes = false
                    }

                    this.agregarProducto(this.compra.tipoingreso_id)
                }.bind(this), 300) 
            },

            onChageTipoCompra (value) {
                // this.validatedFormTipoCompra = true
                this.compra.plancuenta_id = value
                if (this.compra.plancuenta_id === '11') {
                    this.habilitarcredito = true 
                } else {
                    this.habilitarcredito = false
                }
            },

            onChangeFechaCompra (date, dateString) {
				this.compra.fecha_compra = dateString
			},

            agregarProducto (tipoingreso) {
                const urlPost = (tipoingreso === '1') ? URI_PRODUCTO_INICIAL : URI_PRODUCTO_POST_COMPRA

                axios.post(urlPost, {
                    producto_id: this.getproducto_id, 
                    almacen_id: this.almacen_id
                })
                .then(response => {
                    if (response.data.message === 400 || response.data.message === 450) {
                        Swal.fire('Atención!', 'Este Producto necesita ingresar como Inventario Inicial, vaya al Menú Proveedores/Mis Productos para realizarlo...', 'error')
                        this.deshabilitarInventarioInicial = true
                    } else {
                        this.deshabilitarInventarioInicial = false

                        if (this.tablaproducto.length === 0) {
                            this.tablaproducto.push(response.data)
                        } else {
                            let exists = false
                            for (const element of this.tablaproducto) {
                                if (parseInt(element.producto_id) === parseInt(response.data.producto_id)) {
                                    exists = true
                                    break
                                }
                            }

                            if (!exists) {
                                this.tablaproducto.push(response.data)
                            }
                        }
                    }
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            deleteItem (i, j) {
                this.tablaproducto.splice(i, 1)
            },

            validacionCantidad () {
                this.valueCantidad = (this.valueCantidad + '').replace(/[^0-9.]/g, '')
            },

            editCantidad (i, j) {
                this.newCantidad = '' // PARA LIMPIAR O ESTABLECER A SU POSICION ACTUAL
                if (this.valueCantidad === '' || this.valueCantidad === 0) {
                    Swal.fire('Atencion!', 'Introduzca una Cantidad', 'error')
                } else {
                    this.tablaproducto[i].cantidad = this.valueCantidad
                    const subtotal = parseFloat(this.valueCantidad) * parseFloat(this.tablaproducto[i].precioCompra)
                    this.tablaproducto[i].subtotal = this.numberFormat(subtotal)
                }
            },

            validacionDecimal () {
                this.valuePrecioCompra = (this.valuePrecioCompra + '').replace(/[^0-9.]/g, '')
            },

            editPrecioCompra (i, j) {
                if (parseFloat(this.valuePrecioCompra) < 0) {
                    Swal.fire('Atencion!', 'El valor ingresado es menor al Precio de Compra actual por favor vuelva a iniciar de nuevo ...', 'error')
                } else {
                    this.newPrecioCompra = '' // PARA LIMPIAR O ESTABLECER A SU POSICION ACTUAL
                    this.tablaproducto[i].precioCompra = this.numberFormat(this.valuePrecioCompra)
                    const subtotal = parseFloat(this.tablaproducto[i].cantidad) * parseFloat(this.valuePrecioCompra)
                    this.tablaproducto[i].subtotal = this.numberFormat(subtotal)
                }
            },

            validacionDecimalDescuentoCompra () {
                this.valueDescuentoCompra = (this.valueDescuentoCompra + '').replace(/[^0-9.]/g, '')
            },

            editItemDescuentoCompra (i, j) {
                this.itemDescuentoCompra = ''
                if (parseFloat(this.valueDescuentoCompra) > parseFloat(this.tablaproducto[i].precioCompra)) {
                    Swal.fire('Atencion!', 'El valor de descuento es mayor al Precio de Compra actual por favor vuelva a iniciar de nuevo ...', 'error')
                } else {
                    this.tablaproducto[i].descuentoCompra = this.numberFormat(this.valueDescuentoCompra)
                }
            },

			realizarIngreso () {
                if (this.tablaproducto === '') {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto por favor para el ingreso...', 'error')
                    this.buttonKey++
                } else {
                    this.buttonKey++
                    Swal.fire({
                            title: 'Desea Agregar y Guardar?',
                            text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Si, Proceder Ahora',
                            cancelButtonClass: 'btn btn-danger',
                            confirmButtonClass: 'btn btn-success',
                            cancelButtonText: 'No, Proceder!'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
                            const ecode = new EuropioCode()
                            const formData = new FormData()
                            
                            formData.append('almacen_id', (this.compra.almacen_id === 0) ? this.almacenid : parseInt(this.compra.almacen_id))
                            formData.append('proveedor_id', (this.compra.proveedor_id === 'Seleccionar') ? 0 : parseInt(this.compra.proveedor_id))
                            formData.append('plancuenta_id', (this.compra.plancuenta_id === '' || this.compra.plancuenta_id === null) ? '' : parseInt(this.compra.plancuenta_id))
                            formData.append('tipoingreso_id', (this.compra.tipoingreso_id === '' || this.compra.tipoingreso_id === null) ? '' : parseInt(this.compra.tipoingreso_id))

                            formData.append('nroDocumento', (this.compra.nrodocumento === '' || this.compra.nrodocumento === null) ? '' : parseInt(this.compra.nrodocumento))

                            formData.append('razonSocial', (this.compra.razon_social === '' || this.compra.razon_social === null) ? '' : ecode.encode(this.compra.razon_social))
                            formData.append('autorizacion', (this.compra.autorizacion === '' || this.compra.autorizacion === null) ? '' : parseInt(this.compra.autorizacion))
                            formData.append('controlCodigo', (this.compra.codigo_control === '' || this.compra.codigo_control === null) ? '' : ecode.encode(this.compra.codigo_control))
                            formData.append('descuentos', (this.compra.descuentos === '' || this.compra.descuentos === null) ? '' : ecode.encode(this.compra.descuentos.toString()))
                            formData.append('factura', (this.compra.factura === '' || this.compra.factura === null) ? '' : parseInt(this.compra.factura))
                            formData.append('fecha_compra', (this.compra.fecha_compra === '' || this.compra.fecha_compra === null) ? '' : ecode.encode(this.compra.fecha_compra))
                            
                            formData.append('ice', (this.compra.ice === '' || this.compra.ice === null) ? '' : ecode.encode(this.compra.ice.toString()))
                            formData.append('observaciones', (this.compra.observaciones === '' || this.compra.observaciones === null) ? '' : ecode.encode(this.compra.observaciones))

                            formData.append('valores_exceptos', (this.compra.valores_exceptos === '' || this.compra.valores_exceptos === null) ? '' : ecode.encode(this.compra.valores_exceptos.toString()))
                            formData.append('subTotal', (this.originalSubTotal === '' || this.originalSubTotal === null) ? '' : ecode.encode(this.originalSubTotal.toString()))
                            formData.append('decuento', (this.OriginalTotalDescuentoCompra === '' || this.OriginalTotalDescuentoCompra === null) ? '' : ecode.encode(this.OriginalTotalDescuentoCompra.toString()))
                            formData.append('descuentoMoneda', (this.TotalDescuentoCompra === '' || this.TotalDescuentoCompra === null) ? '' : ecode.encode(this.TotalDescuentoCompra.toString()))
                            formData.append('montototal', (this.OriginalmontoTotal === '' || this.OriginalmontoTotal === null) ? '' : ecode.encode(this.OriginalmontoTotal.toString()))

                            formData.append('productos', ecode.encode(JSON.stringify(this.tablaproducto)))

                            const url = (this.compra.tipoingreso_id === '1') ? URI_COMPRA_INVENTARIO_INICIAL : URI_COMPRA_GUARDAR
                            
                            axios.post(url, formData)
                            .then(response => {
                                if (response.status === 201) {
									this.modalInventarioProducto = false
									this.spinnerloading = false
                                    this.getListaScrollMasivoProducto(this.compra)
			                        this.getListaScrollMasivoProductoStock(this.compra)
                                    this.$emit('successCompra', 'Realizado con Exito')
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                            this.buttonKey++
                        }
                    })
                }
            }
        },
    
        computed: {
            subTotal () {
                var sum = 0
                this.tablaproducto.forEach(e => {
                    sum += parseFloat(e.subtotal)
                })
                return this.numberFormat(sum)
            },

            originalSubTotal () {
                var sum = 0
                this.tablaproducto.forEach(e => {
                    sum += parseFloat(e.subtotal)
                })
                return sum
            },

            montoTotal () {
                var data = parseFloat(this.originalSubTotal) - parseFloat(this.descuentoCompra)
                return this.numberFormat(data)
            },

            OriginalmontoTotal () {
                var data = parseFloat(this.originalSubTotal) - parseFloat(this.descuentoCompra)
                return data
            },

            TotalDescuentoCompra () {
                var sum = 0
                this.tablaproducto.forEach(e => {
                    sum += parseFloat(e.descuentoCompra)
                })
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.descuentoCompra = this.numberFormat(sum)
                return this.numberFormat(sum)
            },

            OriginalTotalDescuentoCompra () {
                var sum = 0
                this.tablaproducto.forEach(e => {
                    sum += parseFloat(e.descuentoCompra)
                })
                return sum
            }
        }
    }
</script>
    
<style>

</style>