<template>
    <div class="mb-12">
        <a-row :gutter="16">
            <a-col :xs="24" :md="12">
                <a-form-item>
                    <template v-if="preciocifradecimal === 'ACTIVADO'">
                    <a-radio-group>
                        <a-radio value="option1" checked>
                            Formula Contable Precio con Decimales
                        </a-radio>
                    </a-radio-group>
                    </template>
                    <template v-if="preciocifraentera === 'ACTIVADO'">
                    <a-radio-group>
                        <a-radio value="option1" checked>
                            Formula Contable Precio con Cifras Enteras
                        </a-radio>
                    </a-radio-group>
                    </template>
                    <template v-if="precioreglatres === 'ACTIVADO'">
                    <a-radio-group>
                        <a-radio value="option2" checked>
                            Regla de Tres Precios Cifras Decimales
                        </a-radio>
                    </a-radio-group>
                    </template>
                </a-form-item>

                <a-form-item>
                    <a-radio-group v-model="radioporcentaje">
                    <a-radio :value="0">Aplicar el impuesto del 13 %</a-radio>
                    <a-radio :value="1">Aplicar el impuesto del 16 %</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-col>
        </a-row>
        
        <a-row :gutter="16">
            <a-col :xs="24" :md="12">
                <a-row>
                    <a-col :xs="24" :md="12">
                    <address>
                        Codigo: <span style="font-size:14px; font-weight:700; color:#044a90;">{{ asignacion.productoCodigo }}</span><br>
                        Nombre: <span style="font-size:14px; font-weight:700; color:black;">{{ producto.nombre }}</span>
                    </address>
                    </a-col>
                    <a-col :xs="24" :md="12" class="text-right">
                    <address>
                        Precio Compra: <span style="font-size:20px; font-weight:700; color:var(--element);">{{ asignacion.precioCosto }}</span>
                    </address>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>

        <a-row :gutter="16">
            <a-col :xs="24" :md="6">
                <a-form-item label="Tipo Precio Venta">
                    <a-select :value="asignacion.tipoprecioventaID" @change="onSelectTipoPrecioVenta" style="width: 100%">
                    <a-select-option v-for="(value, index) in lista_tipoprecioventa" :key="index" :value="value.tipoprecioventa_id">
                        {{ value.nombre }}
                    </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :xs="24" :md="4">
                <template v-if="utilidadporcentaje == 'ACTIVADO'">
                    <a-form-item label="Utilidad %">
                    <a-input-number v-if="preciocifradecimal == 'ACTIVADO'" v-model="asignacion.utilidad" @keyup="PorcentajeVentaFTCD($event)" placeholder="%"/>
                    <a-input-number v-if="preciocifraentera == 'ACTIVADO'" v-model="asignacion.utilidad" @keyup="PorcentajeVentaFTCU($event)" placeholder="%"/>
                    <a-input-number v-if="precioreglatres == 'ACTIVADO'" v-model="asignacion.utilidad" @keyup="PorcentajeVentaRDTD($event)" placeholder="%"/>
                    </a-form-item>
                </template>
                <template v-else>
                    <a-form-item :label="`Utilidad ${monedasimbolo}`">
                    <a-input-number v-if="preciocifradecimal == 'ACTIVADO'" v-model="asignacion.utilidad" @keyup="PorcentajeVentaMoneyFTCD($event)" :placeholder="monedasimbolo"/>
                    <a-input-number v-if="preciocifraentera == 'ACTIVADO'" v-model="asignacion.utilidad" @keyup="PorcentajeVentaMoneyFTCU($event)" :placeholder="monedasimbolo"/>
                    <a-input-number v-if="precioreglatres == 'ACTIVADO'" v-model="asignacion.utilidad" @keyup="PorcentajeVentaMoneyRDTD($event)" :placeholder="monedasimbolo"/>
                    </a-form-item>
                </template>
            </a-col>

            <a-col :xs="24" :md="4">
                <template v-if="utilidadporcentaje == 'ACTIVADO'">
                    <a-form-item label="Precio Venta">
                    <a-input v-model="asignacion.precioventa" @keyup="VentaPorcentaje($event)" placeholder="0.00"/>
                    </a-form-item>
                </template>
                <template v-else>
                    <a-form-item label="Precio Venta">
                    <a-input v-model="asignacion.precioventa" @keyup="VentaPorcentajeMoney($event)" placeholder="0.00"/>
                    </a-form-item>
                </template>
            </a-col>

            <a-col :xs="24" :md="4">
                <a-form-item label="P. Venta Facturado">
                    <a-input v-model="asignacion.preciofacturado" placeholder="0.00"/>
                </a-form-item>
            </a-col>

            <a-col :xs="24" :md="6">
                <a-form-item label="Accion">
                    <a-button type="primary" @click="guardarPrecioVenta()" block>
                    <a-icon type="plus"/> Agregar Precio
                    </a-button>
                </a-form-item>
            </a-col>
        </a-row>

        <div class="row">
            <div class="box-body table-responsive no-padding">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th class="text-center">Nro</th>
                            <th class="text-center">Tipo Precio Venta</th>
                            <th class="text-center">Fecha Registro</th>
                            <th v-if="utilidadporcentaje == 'ACTIVADO'" class="text-center">Utilidad %</th>
                            <th v-if="utilidadporcentaje == 'DESACTIVADO'" class="text-center">Utilidad {{ monedasimbolo }}</th>
                            <th class="text-center">Precio <br> Venta</th>
                            <th class="text-center">P. Venta <br> Facturado</th>
                            <th class="text-center">Elegir Precio</th>
                            <th class="text-center">Estado</th>
                            <th class="text-center"></th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in listapreciosventa" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <span style="font-size:12px; font-weight:700; color:#142637;">{{ item.tipoprecioventa.nombre }}</span>
                            </td>
                            <td>
                                <span style="font-size:12px; color:#0E7404;"><a-icon type="plus" :style="{ fontSize: '10px', color: '#0E7404' }" /> {{ formatDate(item.created_at) }}</span><br>
                                <span style="font-size:12px; color:#0752B9;"><a-icon type="edit" :style="{ fontSize: '10px', color: '#0752B9' }" /> {{ formatDate(item.updated_at) }}</span>
                            </td>
                            <td class="text-center" style="font-weight:700; font-size:18px;">{{ item.utilidad }}</td>
                            <td class="text-center" style="font-weight:700; font-size:18px; color:#297b77;" bgcolor="#eefaff">{{ item.precioValor }}</td>
                            <td class="text-center" style="font-weight:700; font-size:18px; color:green;" bgcolor="#ffffea">{{ item.precioFacturado }}</td>
                            <td>
                                <center><a-switch :checked="item.switchValue" @click.once="() => onSwitchVisualizacion(index, item)" :key="buttonKeyActualizarEstado"/></center>
                            </td>
                            <td>
                                <span style="font-size:11px; color:black;">
                                    <a-tag v-if="item.estado_presentacion === 'NUEVO'" color="green">
                                        {{ item.estado_presentacion }}
                                    </a-tag>
                                    <a-tag v-if="item.estado_presentacion === 'NORMAL'" color="blue">
                                        {{ item.estado_presentacion }}
                                    </a-tag>
                                    <a-tag v-if="item.estado_presentacion === 'DESCUENTO'" color="red">
                                        {{ item.estado_presentacion }}
                                    </a-tag>
                                    <a-tag v-if="item.estado_presentacion === 'OFERTA'" color="purple">
                                        {{ item.estado_presentacion }}
                                    </a-tag>
                                </span><br>
                                <a-button size="small" type="dashed" @click.once="editarEstadoPresentacion(item)" :key="buttonKeyActualizarEstado">
                                    <a-icon type="retweet" /> Cambiar
                                </a-button>
                            </td>
                            <td>
                                <a-button size="large" type="dashed" @click.prevent="abrirModalEditarPrecioVenta(item)">
                                    <a-icon type="edit" :style="{ fontSize: '15px' }"/> Editar
                                </a-button>
                            </td>
                            <td>
                                <a href="#" @click.prevent="EliminarPrecioVenta(item.precioventa_id)">
                                    <a-icon type="rest" theme="filled" :style="{ fontSize: '30px', color: '#EE0808' }" />
                                </a>
                            </td>
                        </tr>
                    </tbody> 
                </table>
            </div>
        </div>

        <!--EDITAR PRECIO DE VENTA-->
        <a-modal
                v-model="modalEditarPrecioVenta"
                title="EDITAR PRECIO DE VENTA"
                :closable="true"
                :destroyOnClose="true"
                :maskClosable="false"
                :dialog-style="{ top: '5px' }"
                :width="360"
                :zIndex="1051"
                >
        
                <div class="row mb-12">
                    <div class="form-group">
                        <center>
                            <span style="font-weight:700;">
                                <a-tag color="blue">
                                    {{ asignacion.nombreTipoPrecioVenta }}
                                </a-tag>
                            </span>
                        </center>
                        <br>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Precio Venta Unitario: <span style="font-size:14px; font-weight:700; color:#05729b;"> *</span></label>
                                <a-input-number size="large" :min="0" :max="100000" :value="venta.precioventa" @change="onChangePrecioVenta" placeholder="0.00" style="width: 100%"/>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Precio Venta Unitario Facturado: <span style="font-size:14px; font-weight:700; color:#05729b;"> *</span></label>
                                <a-input-number size="large" :min="0" :max="100000" :value="venta.preciofacturado" @change="onChangePrecioVentaFacturado" placeholder="0.00" style="width: 100%"/>
                            </div>
                        </div>
                    </div>
                </div>
        
                <template slot="footer">
                    <a-button key="back" @click="modalEditarPrecioVenta = false">
                        CANCELAR
                    </a-button>
                    <a-button @click.once="editarPrecioVenta()" :key="buttonKeyEditarPrecioVenta" type="primary">ACTUALIZAR PRECIO DE VENTA</a-button>
                </template>
        </a-modal>

        <!--ESTADO PRESENTACION-->
        <a-modal
            v-model="modalEstadoPresentacion"
            :title="false"
            :footer="false"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="320"
            :zIndex="1045"
                >
                <div class="row">
                    <a-col :span="24">
                        <h3>{{ asignacion.nombreTipoPrecioVenta }}</h3>
                        <label class="control-label">Tipo de Presentacion:</label>
                        <a-select size="large" :value="venta.estadoPresentacion" @change="onSelectPresentacion" style="width: 100%">
                            <a-select-option v-for="(value, index) in listaPresentacion" :key="index" :value="value.nombre"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>
                </div>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>
    
<script>
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
	import numeral from 'numeral'
    import { mapGetters, mapActions } from 'vuex'
    import { 
		URI_TIPOPRECIOVENTA_LISTAR,
        URI_PORCENAJEIMPUESTO_LISTAR,
        URI_PRECIOVENTA_GUARDAR,
        URI_PRECIOVENTA_LISTAR,
        URI_PRECIOVENTA_ELIMINAR,
        URI_PRECIOVENTA_EDITAR
	} from '@/utils/constants'

    function roundToTwo (num) {    
        return +(Math.round(num + 'e+2') + 'e-2')
	}

    export default {

        props: {
            monedasimbolo: { type: String, required: true },
            utilidadporcentaje: { type: String, required: true },
            preciocifradecimal: { type: String, required: true },
            preciocifraentera: { type: String, required: true },
            precioreglatres: { type: String, required: true },
            almacenid: { type: Number, required: true },
            productoid: { type: Number, required: true },
            proveedorid: { type: Number, required: true },
            utilidad: { type: Number, required: true },
            producto: { required: true },
            preciocompra: { type: String, required: true }
        },

        components: {
        
        },
    
        data () {
            return {
                lista_tipoprecioventa: [],
                asignacion: {
                    precioventaID: '',
                    tipoprecioventaID: 'Seleccionar',
                    nombreTipoPrecioVenta: '',
                    almacenID: '',
                    productoID: '',
                    proveedorID: '',
                    utilidad: '',
                    precioventa: '',
                    productoCodigo: '',
                    precioCosto: '',
                    preciofacturado: ''
                },
                porcentaje: {
                    iva: '',
                    it: ''
                },
                radioporcentaje: 0,
                listapreciosventa: [],
                modalEditarPrecioVenta: false,
                buttonKeyEditarPrecioVenta: 10,
                buttonKeyActualizarEstado: 20,
                venta: {
                    precioventaID: '',
                    utilidad: '',
                    precioventa: '',
                    preciofacturado: '',
                    estado: '',
                    estadoPresentacion: ''
                },
                spinnerloading: false,
                form: {
                    visualizacion: false
                },
                listaPresentacion: [
                    { id: 1, nombre: 'NUEVO' },
                    { id: 2, nombre: 'OFERTA' },
                    { id: 3, nombre: 'DESCUENTO' },
                    { id: 4, nombre: 'NORMAL' }
                ],
                modalEstadoPresentacion: false
            }
        }, 
    
        created () {
            this.getTipoPrecioVenta()
            this.obtenerPorcentajeImpuesto()
            this.cargarDatos()
        },
    
        methods: {
            ...mapActions('precioventa', ['updateStatePrecioVenta']),
            ...mapActions('producto', ['getListaScrollMasivoProducto', 'getListaScrollMasivoProductoStock']),

            numberFormat (number) {
				return utils.formatMoney(number, 2, '.', '')
			},

            formatDate (value) {
                return moment(value).format('DD/MM/YYYY h:mm:ss A')
            },

            getTipoPrecioVenta () {
                this.spinnerloading = true
                axios.get(URI_TIPOPRECIOVENTA_LISTAR)
                .then(response => {
                    this.lista_tipoprecioventa = response.data
                    this.asignacion.tipoprecioventaID = Object.values(this.lista_tipoprecioventa).filter(x => typeof x !== 'undefined').shift().tipoprecioventa_id.toString()
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            obtenerPorcentajeImpuesto () {
                const formData = new FormData()
                formData.append('almacenID', this.almacenid)

                axios.post(URI_PORCENAJEIMPUESTO_LISTAR, formData)
                .then(response => {
                    this.porcentaje.iva = response.data.iva
                    this.porcentaje.it = response.data.it
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            resetDatos () {
                this.asignacion.almacenID = ''
                this.asignacion.productoID = ''
                this.asignacion.proveedorID = ''
                this.asignacion.utilidad = ''
                this.asignacion.productoCodigo = ''
                this.asignacion.precioCosto = ''
            },

            cargarDatos () {
                this.resetDatos()
                this.asignacion.almacenID = this.almacenid
                this.asignacion.productoID = this.productoid
                this.asignacion.proveedorID = this.proveedorid
                this.asignacion.utilidad = this.utilidad
                this.asignacion.productoCodigo = this.producto.codigo
                this.asignacion.precioCosto = this.preciocompra

                // START CALCULO AUTOMATICO DE PRECIOS VENTAS PORCENTAJES POR DEFECTO //
                if (this.preciocifradecimal === 'ACTIVADO') {
                    const clave = (parseInt(this.asignacion.utilidad) / 100)
                    const valor = 1 - clave
                    const rand = parseFloat(numeral(this.asignacion.precioCosto).value()) / valor
                    this.asignacion.precioventa = this.numberFormat(rand)

                    const impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    const impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(impuesto)
                } else if (this.preciocifraentera === 'ACTIVADO') {
                    const clave = (parseInt(this.asignacion.utilidad) / 100)
                    const valor = 1 - clave
                    const rand = parseFloat(numeral(this.asignacion.precioCosto).value()) / valor
                    this.asignacion.precioventa = this.numberFormat(Math.ceil(rand))

                    const impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    const impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(Math.ceil(impuesto))
                } else if (this.precioreglatres === 'ACTIVADO') {
                    const costo = parseFloat(numeral(this.asignacion.precioCosto).value())
                    const utilidad = parseInt(this.asignacion.utilidad)
                    const clave = (utilidad / 100)
                    const valor = (costo * clave)
                    const rand = parseFloat(costo) + parseFloat(valor)
                    const total = this.numberFormat(rand)
                    this.asignacion.precioventa = total

                    const impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    const impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(impuesto)
				}
				// END CALCULO AUTOMATICO DE PRECIOS VENTAS PORCENTAJES POR DEFECTO //
                this.ObtenerListaPrecioVenta(this.asignacion.almacenID, this.asignacion.productoID, this.asignacion.proveedorID)
            },

            onSelectTipoPrecioVenta (value) {
                this.asignacion.tipoprecioventaID = value
            },

            /** Precio de Venta con Cifras Decimales - segun Utilidades Formula Contable 1 / ANVERSO **/    
            PorcentajeVentaFTCD ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    var clave = (parseInt(this.asignacion.utilidad) / 100)
                    var valor = 1 - clave
                    var rand = parseFloat(numeral(this.asignacion.precioCosto).value()) / valor
                    this.asignacion.precioventa = this.numberFormat(rand)

                    var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    var impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(impuesto)
                } else {
                    $event.preventDefault()
                }
            },

            /** Precio de Venta con Cifras Enteras - Segun Utilidades Formula Contable 2 / ANVERSO **/
            PorcentajeVentaFTCU ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    var clave = (parseInt(this.asignacion.utilidad) / 100)
                    var valor = 1 - clave
                    var rand = parseFloat(numeral(this.asignacion.precioCosto).value()) / valor
                    this.asignacion.precioventa = this.numberFormat(Math.ceil(rand))

                    var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    var impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(Math.ceil(impuesto))
                } else {
                    $event.preventDefault()
                }
            },

            // Precio de Venta con Cifras Decimales - Regla de Tres 1 / ANVERSO
            PorcentajeVentaRDTD ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    var costo = parseFloat(numeral(this.asignacion.precioCosto).value())
                    var utilidad = parseInt(this.asignacion.utilidad)
                    var clave = (utilidad / 100)
                    var valor = (costo * clave)
                    var rand = parseFloat(costo) + parseFloat(valor)
                    var total = this.numberFormat(rand)
                    this.asignacion.precioventa = total

                    var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    var impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(impuesto)
                } else {
                    $event.preventDefault()
                }
            },

            /** Precio de Venta para mostrar Utilidades segun el precio / INVERSO **/
            VentaPorcentaje ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    var costo = parseFloat(numeral(this.asignacion.precioCosto).value())
					var venta = this.asignacion.precioventa
					
					if (parseFloat(venta) <= parseFloat(costo)) {
                        console.log('Ingrese un valor Mayor al Costo de Compra del Producto...')
                    } else {
						var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
						var sumaImpuesto = parseFloat(venta) + parseFloat(venta * impuestoPorcentaje)
						var impuesto = roundToTwo(sumaImpuesto)
						this.asignacion.preciofacturado = this.numberFormat(impuesto)

						var valor = parseFloat(venta) - parseFloat(costo)
						var rand = valor / venta
						var subtotal = this.numberFormat(rand)
						var total = subtotal * 100
						var x = Math.ceil(total)

                        this.asignacion.utilidad = x
					}
                } else {
                    $event.preventDefault()
                }
			},

            /** Precio de Venta con Cifras Decimales - segun Utilidades Formula Contable 1 / ANVERSO **/    
            PorcentajeVentaMoneyFTCD ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {					
					var rand = parseFloat(numeral(this.asignacion.precioCosto).value()) + parseInt(this.asignacion.utilidad)
                    this.asignacion.precioventa = this.numberFormat(rand)

                    var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    var impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(impuesto)
                } else {
                    $event.preventDefault()
                }
            },

            // Precio de Venta con Cifras Enteras - Segun Utilidades Formula Contable 2 / ANVERSO
            PorcentajeVentaMoneyFTCU ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    var rand = parseFloat(numeral(this.asignacion.precioCosto).value()) + parseInt(this.asignacion.utilidad)
                    this.asignacion.precioventa = this.numberFormat(Math.ceil(rand))

                    var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    var impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(Math.ceil(impuesto))
                } else {
                    $event.preventDefault()
                }
            },

            /** Precio de Venta con Cifras Decimales - Regla de Tres 1 / ANVERSO **/
            PorcentajeVentaMoneyRDTD ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
					var costo = parseFloat(numeral(this.asignacion.precioCosto).value())
                    var utilidad = parseInt(this.asignacion.utilidad)
                    var rand = parseFloat(costo) + utilidad
                    var total = this.numberFormat(rand)
                    this.asignacion.precioventa = total

                    var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
                    var impuesto = roundToTwo(rand + (rand * impuestoPorcentaje))
                    this.asignacion.preciofacturado = this.numberFormat(impuesto)
                } else {
                    $event.preventDefault()
                }
            },

            /** Precio de Venta para mostrar Utilidades segun el precio / INVERSO **/
            VentaPorcentajeMoney ($event) {
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
					var costo = parseFloat(numeral(this.asignacion.precioCosto).value())
					var venta = this.asignacion.precioventa
					
					if (parseFloat(venta) <= parseFloat(costo)) {
						console.log('Ingrese un valor Mayor al Costo de Compra del Producto...')
                    } else {
						var impuestoPorcentaje = roundToTwo((this.porcentajevalido) / (1 - this.porcentajevalido))
						var sumaImpuesto = parseFloat(venta) + parseFloat(venta * impuestoPorcentaje)
						var impuesto = roundToTwo(sumaImpuesto)
						this.asignacion.preciofacturado = this.numberFormat(impuesto)

						var valor = parseFloat(venta) - parseFloat(costo)
						var rand = valor / venta
						var subtotal = this.numberFormat(rand)
						var total = subtotal * 100
						var x = Math.ceil(total)

                        this.asignacion.utilidad = x
					}
                } else {
                    $event.preventDefault()
                }
			},

            editarEstadoPresentacion (item) {
                this.venta.precioventaID = ''
                this.venta.utilidad = ''
                this.venta.precioventa = ''
                this.venta.preciofacturado = ''
                this.venta.estadoPresentacion = ''

                setTimeout(function () {
                    this.asignacion.nombreTipoPrecioVenta = item.tipoprecioventa.nombre
                    this.venta.precioventaID = item.precioventa_id
                    this.venta.utilidad = item.utilidad
                    this.venta.precioventa = item.precioValor
                    this.venta.preciofacturado = item.precioFacturado
                    this.venta.estadoPresentacion = item.estado_presentacion
                    this.modalEstadoPresentacion = true
                }.bind(this), 500)

                this.buttonKeyActualizarEstado++
            },

            onSelectPresentacion (value) {
                this.venta.estadoPresentacion = value
                this.editarPrecioVenta()
            },

            onSwitchVisualizacion (index, item) {
                this.venta.precioventaID = ''
                this.venta.utilidad = ''
                this.venta.precioventa = ''
                this.venta.preciofacturado = ''
                this.venta.estadoPresentacion = ''

                setTimeout(function () {
                    this.asignacion.nombreTipoPrecioVenta = item.tipoprecioventa.nombre
                    this.venta.precioventaID = item.precioventa_id
                    this.venta.utilidad = item.utilidad
                    this.venta.precioventa = item.precioValor
                    this.venta.preciofacturado = item.precioFacturado
                    this.venta.estadoPresentacion = item.estado_presentacion

                    this.listapreciosventa.forEach((item, i) => {
                        if (i === index) {
                            item.estado = 'HABILITADO'
                            item.switchValue = true
                        } else {
                            item.estado = 'DESHABILITADO'
                            item.switchValue = false
                        }
                    })

                    const lista = this.listapreciosventa.map(item => {
                        return {
                            precioventa_id: item.precioventa_id,
                            estado: item.estado
                        }
                    })

                    this.spinnerloading = true
                    this.updateStatePrecioVenta(lista) 
                    .then(response => {
                        if (response.status === 201) {
                            this.getTipoPrecioVenta()
                            this.obtenerPorcentajeImpuesto()
                            this.cargarDatos()
                            this.spinnerloading = false
                            this.modalEstadoPresentacion = true
                            this.$emit('successVenta', 'Realizado con Exito')
                        }
                    })
                    .catch(error => {
                        this.loading = false
                        utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                    })

                    this.buttonKeyActualizarEstado++
                }.bind(this), 500)
            },
		
            ObtenerListaPrecioVenta (Almacen, Producto, Proveedor) {
                const formData = new FormData()
                formData.append('almacenID', Almacen)
                formData.append('productoID', Producto)
                formData.append('proveedorID', Proveedor)

                axios.post(URI_PRECIOVENTA_LISTAR, formData)
                .then(response => {
                    if (response.status === 200) {
                        const listapreciosventa = response.data

                        this.listapreciosventa = listapreciosventa.map(item => {
                            if (item.estado === 'HABILITADO') {
                                return { ...item, switchValue: true }
                            } else {
                                return { ...item, switchValue: false }
                            }
                        })

                        this.spinnerloading = false
                    }
                }).catch(() => {
                    Swal.fire('Ups! Hubo un problema', 'Error al guardar', 'error')
                })
            },

            abrirModalEditarPrecioVenta (item) {
                setTimeout(function () {
                    this.asignacion.nombreTipoPrecioVenta = item.tipoprecioventa.nombre 

                    this.venta.precioventaID = item.precioventa_id
                    this.venta.utilidad = item.utilidad
                    this.venta.precioventa = item.precioValor
                    this.venta.preciofacturado = item.precioFacturado
                    this.venta.estadoPresentacion = item.estado_presentacion
                
                    this.modalEditarPrecioVenta = true
                }.bind(this), 300)
            },

            onChangePrecioVenta (value) {
                this.venta.precioventa = value
            },
    
            onChangePrecioVentaFacturado (value) {
                this.venta.preciofacturado = value
            },

            actualizarDatosProductos () {
                const consulta = {
                    almacen_id: this.asignacion.almacenID
                }
                this.getListaScrollMasivoProducto(consulta)
                this.getListaScrollMasivoProductoStock(consulta)
            },

            guardarPrecioVenta () {
                const ecode = new EuropioCode()
                const formData = new FormData()
                formData.append('id', 0)
                formData.append('tipoprecioventaID', this.asignacion.tipoprecioventaID)
                formData.append('almacenID', this.asignacion.almacenID)
                formData.append('productoID', this.asignacion.productoID)
                formData.append('proveedorID', this.asignacion.proveedorID)
                formData.append('utilidad', this.asignacion.utilidad)
                formData.append('precioventa', (this.asignacion.precioventa === null) ? 0.00 : ecode.encode(this.asignacion.precioventa))
                formData.append('preciofacturado', (this.asignacion.preciofacturado === null) ? 0.00 : ecode.encode(this.asignacion.preciofacturado))
                formData.append('estadoPresentacion', 'NORMAL')

                axios.post(URI_PRECIOVENTA_GUARDAR, formData)
                .then(response => {
                    if (response.status === 201) {
                        this.ObtenerListaPrecioVenta(this.asignacion.almacenID, this.asignacion.productoID, this.asignacion.proveedorID)
						this.actualizarDatosProductos()
                        this.$emit('successVenta', 'Realizado con Exito')
                        Swal.fire('Éxito!', 'Has realizado correctamente la operacion.', 'success')
                    }
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            editarPrecioVenta () {
                this.spinnerloading = true
                const ecode = new EuropioCode()
                const formData = new FormData()
                formData.append('precioventaID', this.venta.precioventaID)
                formData.append('utilidad', this.venta.utilidad)
                formData.append('precioventa', (this.venta.precioventa === null) ? 0.00 : ecode.encode(this.venta.precioventa.toString()))
                formData.append('preciofacturado', (this.venta.preciofacturado === null) ? 0.00 : ecode.encode(this.venta.preciofacturado.toString()))
                formData.append('estadoPresentacion', this.venta.estadoPresentacion)

                axios.post(URI_PRECIOVENTA_EDITAR, formData)
                .then(response => {
                    if (response.status === 201) {
                        this.cargarDatos()
                        this.actualizarDatosProductos()
                        this.modalEditarPrecioVenta = false
                        this.modalEstadoPresentacion = false
                        this.spinnerloading = false
                        this.$emit('successVenta', 'Realizado con Exito')
                        Swal.fire('Éxito!', 'Has realizado correctamente la operacion.', 'success')
                    }
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })

                this.buttonKeyEditarPrecioVenta++
            },

            EliminarPrecioVenta (id) {
                Swal.fire({
                        title: 'Desea Eliminar?',
                        text: 'Tome en cuenta que debera volver a registrar de nuevo.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        const formData = new FormData()
                        formData.append('precioventaID', id)
                        
                        axios.post(URI_PRECIOVENTA_ELIMINAR, formData)
                        .then(response => {
                            this.ObtenerListaPrecioVenta(this.asignacion.almacenID, this.asignacion.productoID, this.asignacion.proveedorID)
                            this.actualizarDatosProductos()
                            this.$emit('successVenta', 'Realizado con Exito')
                            Swal.fire('Eliminado con éxito', 'Se ha eliminado el precio de venta...', 'success')
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
            }
        },
    
        computed: {
            porcentajevalido () {
                return (this.radioporcentaje === 0) ? this.porcentaje.iva / 100 : (parseInt(this.porcentaje.iva) + parseInt(this.porcentaje.it)) / 100
            }
        }
    }
</script>